.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  50% { border-color: transparent }
}

.typing {
  word-wrap: break-word;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typewriter cursor */
  white-space: pre-line; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: normal; /* Adjust as needed */
  max-width: 100%;
  animation: 
    typing 3.5s steps(40, end),
    blink .75s step-end infinite;
}

.fade-in {
  animation: fadeIn 6.5s ease-out;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s 2s, opacity 2s linear;
}

.fade-out{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 3s, opacity 3s linear;
}

  /* Adjustments for multi-line support */
  /* display: inline-block;
  word-wrap: break-word;
  overflow: hidden;
  max-width: 100%; /* Ensure it doesn't go off the page */

  /* The animations */
  /* animation: 
    typing 3.5s steps(40, end),
    blink .75s step-end infinite; */

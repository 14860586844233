body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mx-height-100vh{
  max-height: 100vh;
}

.mx-width-50px {
  max-width: 50px;
}

.mx-width-80vw{
  max-width: 80vw;
}

.width-100vw{
  width: 100vw;
}

.height-100vh{
  height: 100vh;
}

.max-h-37rem {
  max-height: 37rem;
}

/* Import the Tailwind CSS */
@import './styles/tailwind.css';